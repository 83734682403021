// src/App.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Signup from './components/Signup';
import Login from './components/Login';
import AdminDashboard from './components/AdminDashboard';
import ChangePassword from './components/ChangePassword';
import Home from './components/Pages/Home';
import About from './components/Pages/About';
import Service from './components/Pages/Service';
import Contacts from './components/Pages/Contacts';
import ContactList from './components/Pages/ContactList';
import NewsList from './components/Pages/NewsList';
import Dashboard from './components/Pages/Dashboard';
import Users from './components/Pages/Users';
import Register from './components/Pages/Register';
import News from './components/Pages/News';
import UpdateNews from './components/Pages/UpdateNews';
import Reviews from './components/Pages/Reviews';
import AddNews from './components/Pages/AddNews';
import AddReview from './components/Pages/AddReview';
import UpdateReview from './components/Pages/UpdateReview';
import ResetPasswordRequest from './components/ResetPasswordRequest';
import ResetPassword from './components/ResetPassword';
import FileUpload from './components/FileUpload';



function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Routes>

            <Route path='/' element={<Home/>}></Route>
            <Route path='/about' element={<About/>}></Route>
            <Route path='/service' element={<Service/>}></Route>
            <Route path='/contacts' element={<Contacts/>}></Route>
            <Route path='/contactlist' element={<ContactList/>}></Route> 
            
            <Route path="/login" element={<Login />} />
            <Route path="/admin" element={<Dashboard />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path='/newslist' element={<NewsList/>}></Route>
            <Route path="/reset-password" element={<ResetPasswordRequest />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            {/* <Route path='/newslist' element={<NewsList/>}></Route> */}
            <Route path='/fileupload' element={<FileUpload/>}></Route>
                <Route path='/dashboard' element={<AdminDashboard/>}>
                <Route path="/dashboard/signup" element={<Signup />} />
                <Route path='/dashboard/user' element={<Users/>}></Route>
                <Route path='/dashboard/register' element={<Register/>}></Route>
                <Route path='/dashboard/contactlist' element={<ContactList/>}></Route>
                <Route path='/dashboard/news' element={<News/>}></Route>
                <Route path='/dashboard/newsUpdate/:id' element={<UpdateNews/>}></Route>
                <Route path='/dashboard/reviews' element={<Reviews/>}></Route>
                <Route path='/dashboard/add_news' element={<AddNews/>}></Route>
                <Route path='/dashboard/add_review' element={<AddReview/>}></Route>
                <Route path='/dashboard/update_review' element={<UpdateReview/>}></Route>
                </Route>
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
