import React from 'react'
import { FaFacebook, FaTelegram, FaPhone, FaEnvelope, FaWhatsapp, FaLocationArrow } from 'react-icons/fa';

const Footer = () => {
  return (
    <React.Fragment>
        <section>
        <div class="footer">
            <div class="footer-content" >
                <h2> About Us</h2>
                <p>
                Temesgen Sisay is the founding attorney 
                at Temesgen Sisay Law office. He has been
                 in the legal profession for over 17 years.
                  He began his career by teaching laws at Bahir
                   Dar University. He has been teaching various 
                   areas of law for 17 years and working as an
                    Advocate at Temesgen Sisay Law Office since 2013.
                </p>
                <div className='footer-link'>
                <a><FaFacebook style={{ color: 'white', fontSize: '30px', }}/></a>
               <a><FaTelegram style={{ color: 'white', fontSize: '30px', }}/></a> 
               <a><FaWhatsapp style={{ color: 'white', fontSize: '30px', }}/></a> 
                </div>
            </div>
            <div class="footer-content" >
                <h2> Quick Contact </h2>
                <p>
                <h6><FaLocationArrow style={{ color: 'white', fontSize: '20px', }} /><span>Bahir Dar City, Tana Sub city, Around NOC  <h5 className='location'> Gas Station, Dibanke Market Center, 1st Floor, Office No. 112</h5></span></h6>
                <h6><FaEnvelope style={{ color: 'white', fontSize: '20px', }} /><span>info@temesgensisay.com</span></h6>
                <h6> <FaPhone style={{ color: 'white', fontSize: '20px', }} /><span>+251913304493</span></h6>
                  
                </p>
            
            </div>
           
        
        </div>
    </section>

    <div class="poweredByDiv">
        <h4>Copyright &#169;2024 Temesgen Sisay Law office  All rights reserved <span>Powered by<a href='https://uttertech.com.et'>uttertechplc</a> </span></h4>
         
    </div>
    </React.Fragment>
  )
}

export default Footer