import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const AddNews = () => {
    const navigate = useNavigate();
    const [date, setDate] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);
    const [message, setMessage] = useState('');

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };
        // password: ''
    
    const handleSubmit = async (e)  =>{
        e.preventDefault();
        const apiurl = "https://teme.temesgensisay.com";
        const formData = new FormData();
        formData.append('date', date);
        formData.append('title', title);
        formData.append('description', description);
        formData.append('image', image);
        try{
            const response = await axios.post(`${apiurl}/api/createnews`, formData,{
        headers: {
            'Content-Type': 'multipart/form-data'
        }
        
    });
    setMessage(response.data.msg);
    navigate('/dashboard/news')
    }
        // .then(res => {
        //     if(res.data.status){
        //        navigate('/dashboard/news')
        //     }
        //     else{
        //         alert(res.data.Error)
        //     }
        // })
        catch (error) {
            setMessage('Error uploading data');
        }
    }
  return (
    <div className='d-flex justify-content-center align-items-center vh-75'>
        <div className='p-3 rounded w-25 border'>
            <div className='text-warning'>
               
            </div>
            <h2>add news</h2>
            <form onSubmit={handleSubmit}>
            <div className='mb-2'>
                <label htmlFor=''> Date</label>
                <input type='date' placeholder='enter date' className='form-control'
                value={date}
                onChange={(e) => setDate(e.target.value)}
                 />
            </div>
            <div className='mb-2'>
                <label htmlFor=''> Title</label>
                <input type='text' placeholder='enter name' className='form-control'
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  />
            </div>
            <div className='mb-2'>
                <label htmlFor=''> Description</label>
                <textarea type='text' placeholder='enter description' className='form-control'
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  />
            </div>
            <div className='mb-2'>
                <input type='file' 
               onChange={handleImageChange}
               />
            </div>
            <button className='btn btn-success w-100'> Add News</button>
            {message && <p>{message}</p>}
        </form>
        </div>
      
    </div>
  )
}

export default AddNews
