import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';


const UpdateNews = ({data}) => {
  const apiurl = "https://teme.temesgensisay.com";
//edit function
const updateDescription = async e =>{
    e.preventDefault();
    try{
        const body = {date, title, description};
        const response = await fetch(`${apiurl}/api/updatenews/${data.id}`,
            {
                method: "PUT",
                headers: { "Content-Type": "application/json"},
                body: JSON.stringify(body)
            }
        );
        window.location = "/dashboard/news";
        console.log(response);
    }
    catch (err){
        console.error(err.message);
    }
}

    console.log(data)
    const [date, setDate] = useState(data.date);
    const [title, setTitle] = useState(data.title);
    const [description, setDescription] = useState(data.description);
  return (
    <React.Fragment>
    <button type="button" class="btn btn-warning" data-toggle="modal" data-target={`#id${data.id}`}>
edit
</button>

{/* <!-- The Modal --> */}

<div class="modal" id={`id${data.id}`}>
<div class="modal-dialog">
<div class="modal-content">

  {/* <!-- Modal Header --> */}
  <div class="modal-header">
    <h4 class="modal-title">edit review</h4>
    <button type="button" class="close" data-dismiss="modal">&times;</button>
  </div>

  {/* <!-- Modal body --> */}
  <div class="modal-body">
    <input type='date' className='form-control' value={date} onChange={e => setDate(e.target.value)}/>
    <input type='text' className='form-control' value={title} onChange={e => setTitle(e.target.value)}/>
    <textarea type='text' className='form-control' value={description} onChange={e => setDescription(e.target.value)}/>
    {/* <input type='file' className='form-control' value={file} onChange={e => setFile(e.target.files[0])}/> */}
  </div>

  {/* <!-- Modal footer --> */}
  <div class="modal-footer">
  <button type="button" class="btn btn-warning" data-dismiss="modal" onClick={e => updateDescription(e)}>edit</button>
    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
  </div>

</div>
</div>
</div>
</React.Fragment>
  )
}

export default UpdateNews