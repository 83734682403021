import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {Link} from 'react-router-dom'
const ContactList = () => {

 const apiurl = "https://teme.temesgensisay.com";
 const [userData, setUserData]= useState([]);
 const [currentPage, setCurrentPage] = useState(1);
 const [itemsPerPage] = useState(5); // Number of items per page
 const [contactDelete, setDeleteData]= useState([]);
useEffect( ()=>{
 fetchData();
},[])
const fetchData =async () => {
    try {
        const result =await axios(`${apiurl}/api/fetchcontact`);
       // console.log(result.data);
        setUserData(result.data)

    }
    catch(err){
        console.log("some thing wrong");
    }
}

  //delete contact function
  const handleDelete = async (id) => {
    try{
const deleteContact = await fetch(`${apiurl}/api/deletecontact/${id}`,{
  method: "DELETE"
});
setDeleteData(contactDelete.filter(data => data.id !== id));
    }catch (err){
      console.error(err.message)
    }
  }

  
        // Logic to calculate pagination
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = userData.slice(indexOfFirstItem, indexOfLastItem);
          // Logic to change page
          const paginate = (pageNumber) => setCurrentPage(pageNumber);
          const nextPage = () => setCurrentPage(currentPage + 1);
          const prevPage = () => setCurrentPage(currentPage - 1);
  return (
    <React.Fragment>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h2 className="m2">User data</h2>
                     
                    

                    <table className='table table-bordered'>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Date</th>
                                <th>Subject</th>
                                <th>Message</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                currentItems.map((user, index) =>(
                                    <tr key={index}>
                                    <td>{user.name}</td>
                                    <td>{user.email}</td>
                                    <td>{user.date}</td>
                                    <td>{user.subject}</td>
                                    <td>{user.message}</td>
                                    
                                   
                                    <td>
                                       <button onClick={() => handleDelete(user.id)} className='btn btn-danger'>delete</button>
                                        {/* <Link to={'/deleteUser'} className='btn btn-danger '>delete</Link> */}
                                    </td>
                                </tr>
                                ))
                            }
                          
                        </tbody>
                    </table>
                    <div className='d-flex justify-content-center'>
        {/* Pagination buttons */}
        {/* <button onClick={prevPage} disabled={currentPage === 1}>Prev</button> */}
        {Array.from({ length: Math.ceil(userData.length / itemsPerPage) }).map((_, index) => (
          <button key={index} onClick={() => paginate(index + 1)}>
            {index + 1}
          </button>
        ))}
         {/* <button onClick={nextPage} disabled={currentPage === Math.ceil(userData.length / itemsPerPage)}>Next</button> */}
      </div>
                </div>
            </div>
        </div>
  
    </React.Fragment>
  )
//   function handleDelete (id) {
//     const conf = window.confirm("Do you want to delete");
//     if(conf){
//         axios.delete('http://localhost:8081/delete/'+id)
//         .then(res =>{
//             alert('record has deleted');
//         }).catch(err =>console.log(err))
//     }
//   }
}

export default ContactList