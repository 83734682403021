import React from 'react'
import { FaFileImage, FaPeopleCarry, FaUserAltSlash } from 'react-icons/fa';
import '../style.css'
import { useCollapse } from 'react-collapsed'
import Navbar from '../Navbar'
import Contact from './Contact'
import Footer from './Footer'
// import Service from './Service'

import HomeSite from './HomeSite'

import Revies from './Revies';
import ScrollToToButton from './ScrollToToButton';





const Home = () => {
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
  return (
    <>
    
     <Navbar/>
 
     {/* <SlideImage/> */}
     <section class="home" >
       
    {/* <HomePage/> */}
    <HomeSite/>

    
   
    </section>
    {/* <br/><br/> */}
    <div className='home-container'>
    {/* <div class="status">
        <div class="box-container">
            <div class="box">
                <h2>20+</h2>
                <p>years in this field</p>
            </div>
            <div class="box">
                <h2>100+</h2>
                <p>legal issues for both small and large business</p>
            </div>
            <div class="box">
                <h2>98%</h2>
                <p>success rate in resolving favourable settlement for cliements</p>
            </div>
        </div>
    </div> */}
    {/* <ScrollToToButton/> */}
    <section class="about">
<div class="image">
    <img src ="images/about_image.jpg"/>
</div>
<div class="content">
    <span>More about us</span>
    {/* <h1> More about us</h1> */}
    <p>Temesgen Sisay is the founding attorney at Temesgen Sisay Law office. He has been in the legal profession for over 17 years. He began his career by teaching laws at Bahir Dar University. He has been teaching various areas of law for 17 years and working as an Advocate at Temesgen Sisay Law Office since 2013. Temesgen  is serving legal needs of a wide portfolio of clients both local and international across a broad spectrum of industry sector.  He is highly committed and responsive to clients’ needs in attaining practical approaches to legal issues as well as effective legal solutions to the increasingly challenging and complex business environment 
Mr. Temesgen holds a law degree (LL.B.) from Haramaya University and Master’s Degree from Central European University, Hungary.  He has conducted several researches in various areas of Law.  Mr. Temesgen is a member of Ethiopian Bar Association and Amhara Region Bar Association
 </p>
     
     {/* <div class="award">
        <div class="box">
            <h3>100+</h3>
            <p>happy clients</p>
        </div>
        <div class="box">
            <h3>10+</h3>
            <p>awards atetained</p>
        </div>
        <div class="box">
            <h3>50+</h3>
            <p>successful issues</p>
        </div>
     </div>   */}
   
</div>


    </section>
    <ScrollToToButton/>
    <section className='service-section'>
        {/* <p>what we do</p> */}
        <h3>Service Sectors </h3>
            <div className='service'>
                <div className='service-content'>
                    <div>
                    <img src='images/contract.jpg'/>
                        </div>
                    <div>
                    <h2> Contract</h2>
                    
                    <p>Our Office advises clients on general contract formation and administration issues and our advisory services cover:
                     <ul>
                    <li>General Contract</li>
                        <li>Sales Contract</li>
                        <li>Construction contract and related issues </li>
                      </ul>
                   
                    </p>
                   
                    
                </div>
                </div>
                <div className='service-content'>
                <div>
                <img src='images/ajency.jpeg'/>
                    </div>
                <div>
                <h2>Agency Law</h2>
                <p>In today’s complex and diversified business environment, it will be difficult to handle business by oneself and 
                    it may require appointing an agent on once behalf.  Our office advices clients how to handle their relationship with
                    either the agent or the principal considering different areas of law.
                 
                     </p>
                   
                </div>
                </div>
                <div className='service-content'>
                <div>
                <img src='images/banking.jpeg'/>
                     </div>
                <div>
                <h2>Banking and Finance</h2>
                <p>Over the years, our office has built key industry practice in the banking and financial sector by advising local
                     and global banking entities, development funds and micro finance institutions. Our office has acted as lender’s 
                    counsel as well as representing clients figuring in the relevant transaction as borrowers. Among the services;</p>
                </div>
                </div>
                <div className='service-content'>
                 <div>
                 <img src='images/corporate.jpeg'/>
                    </div>
                 <div>
                <h2> Corporate Law</h2>
                <p>Our office and its lawyers have an experience in proactively pinpointing and maximizing on regulatory 
                    opportunities while ensuring a compliant presence and operation. We offer best-in-class and actionable
                     advisory and representation services on corporate structuring, tax compliance, licensing, privatization.</p>
                </div>
                </div>
                <div className='service-content'>
                <div>
                <img src='images/labor.jpeg'/>
                    </div>
                <div>
                <h2>Employment and Labour Law</h2>
                <p>Our office advises clients in all matters involving labor and employment law, including on hiring,
                     terminations, drafting contracts and workplace policies and procedures, and workplace investigations.
                      Our potential clients largely consist of foreign companies doing business in Ethiopia. </p>
                </div>
                </div>
                <div className='service-content'>
                <div>
                <img src='images/arbitration.jpeg'/>
                    </div>
                <div>
                <h2>Arbitration Law</h2>
                <p>Complex business relationships at times can lead to disagreements.
                     Often is the case that businesses frequently resolve their 
                     disputes amicably and that fosters an improved and continued relationship between
                      the parties. When that fails however, litigation before courts or other alternative
                       dispute settlement procedures are required.</p>
                </div>
                </div>
                {/* <div className='service-content'>
                <div><FaPeopleCarry style={{ color: 'white', fontSize: '60px', }}/></div>
                <div>
                <h2>Family Law</h2>
                <p>The mission of our company is usually to provide high-quality,
                         innovative and cost-effective IT solutions that meet the needs and
                          expectations of our clients and help them achieve their goals</p>
                </div>
                </div> */}
            </div>
        </section>
          {/* <Revies/> */}
   

</div>

        {/* <Contact/> */}
    {/* <a href="#" class="gotop"> <img src="images/arrow-up.jpg" alt=""/></a> */}

    <Footer/>
    
</>
  
  )
 
}

export default Home
