import React from 'react'
import {  FaBalanceScale } from 'react-icons/fa';
import { MDBCarousel, MDBCarouselItem, MDBCarouselCaption } from 'mdb-react-ui-kit';
import img1 from '../../image/back.jpg'
import img2 from '../../image/court.jpeg'
import img3 from '../../image/court2.jpeg'

const HomeSite = () => {
  return (
    <React.Fragment>
    {/* <div className='homeSite'>
        <div className="homeSite-coll">
            <h1>Welcome to <br/> Temesgen Advocate</h1>
            <p>Temesgen has the ability to provide each client with individualized legal service that is tailored to meet their specific needs. Temesgen has been recognized in the legal community for his diversified areas of practice and legal experience, high quality legal work, practical approach and excellent results. He advises major companies including foreign owned companies operating in Ethiopia.
            </p>
        </div>
        <div className="homeSite-coll">
            <FaBalanceScale style={{ color: '#ec9f6b', fontSize: '400px' }}/>
        </div>
    </div> */}
    <MDBCarousel showIndicators showControls fade>
      {/* <MDBCarouselItem itemId={1}>
        <img src={img1} className='d-block w-100' alt='...' height="500px" />
        <MDBCarouselCaption>
          <h5 style={{fontSize:50}}>First slide label</h5>
          <p style={{fontSize:30}}>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </MDBCarouselCaption>
      </MDBCarouselItem> */}

      <MDBCarouselItem itemId={1}>
        <img src={img2} className='d-block w-100' alt='...' height="550px" />
        {/* <MDBCarouselCaption>
          <h5 style={{fontSize:50}}>Second slide label</h5>
          <p style={{fontSize:30}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </MDBCarouselCaption> */}
      </MDBCarouselItem>

      <MDBCarouselItem itemId={2}>
        <img src={img3} className='d-block w-100' alt='...' height="550px" />
        {/* <MDBCarouselCaption>
          <h5 style={{fontSize:50}}>Third slide label</h5>
          <p style={{fontSize:30}}>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
        </MDBCarouselCaption> */}
      </MDBCarouselItem>
    </MDBCarousel>
    </React.Fragment>
  )
}

export default HomeSite