import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import UpdateNews from './UpdateNews';


const News = () => {
  const apiurl = "https://teme.temesgensisay.com";
    const [data,  setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5); // Number of items per page
    // const [news, setNews] = useState([])
    
    useEffect(() =>{
      const fetchnews = async () =>{
        try{
          const response = await axios.get(`${apiurl}/app/fetchnews`);
          setData(response.data);
          
        } catch (error) {
            console.error('Error fetching data', error);
        }
      }
   
    fetchnews();
  }, []);

        // Logic to calculate pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    // Logic to change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const nextPage = () => setCurrentPage(currentPage + 1);
    const prevPage = () => setCurrentPage(currentPage - 1);
  return (
    <div className='px-5 mt-3'>
        <div className='d-flex justify-content-center'>
            <h3>News list</h3>
        </div>
        <Link to="/dashboard/add_news" className='btn btn-success'> Add News</Link>
        <div className='mt-3'>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Title</th>
                        <th>Description</th>
                        <th>image</th>
                        <th>Action</th>
                        {/* <th>delete</th> */}
                    </tr>
                </thead>
                <tbody>
                  {
                    currentItems.map((data,index) =>{
                        return <tr key={index}>
                            <td>{data.date}</td>
                            <td>{data.title}</td>
                            <td>{data.description}</td>
                            <td> <img 
                              style={{width:"100px"}} 
                              src={`data:image/jpeg;base64,${data.data}`} 
                              alt={data.name} 
                            /></td>
                            <td className='d-flex'>
                            <button className='btn btn-warning m-3'><UpdateNews  data={data}/></button>
                            <button onClick={ e => handleDelete(data.id)} className='btn btn-danger m-3'>delete</button>
                                </td>
                               
                        </tr>
                    })
                  }
                 
                </tbody>
            </table>
            <div className='d-flex justify-content-center'>
        {/* Pagination buttons */}
        <button onClick={prevPage} disabled={currentPage === 1}>Prev</button>
        {Array.from({ length: Math.ceil(data.length / itemsPerPage) }).map((_, index) => (
          <button key={index} onClick={() => paginate(index + 1)}>
            {index + 1}
          </button>
        ))}
         <button onClick={nextPage} disabled={currentPage === Math.ceil(data.length / itemsPerPage)}>Next</button>
      </div>
        </div>
    </div>
  );
  function handleDelete (id) {
    const conf = window.confirm("Do you want to delete");
    if(conf){
        axios.delete(`${apiurl}/api/deletenews/`+id)
        .then(res =>{
            alert('record has deleted');
        }).catch(err =>console.log(err))
    }
  }
}

export default News