import React from 'react'
import Navbar from '../Navbar'
import { FaFileImage } from 'react-icons/fa';
import Footer from './Footer'
import ScrollToToButton from './ScrollToToButton';

const Service = () => {
  return (
    <React.Fragment>

      <Navbar/>
      <div style={{fontSize: "30px", color: "#111",padding: "40px", background: "#d9dee8", textAlign: "center" }}><h3>Service Sectors </h3></div>
      <section className='service-section2'>
        {/* <p>what we do</p> */}
        
            <div className='service2'>
                <div className='service-content2'>
                    <div>
                      <img src='images/contract.jpg'/>
                    </div>
                    <br/>
                    <div>
                    <h2> Contract</h2>
                    <p>TOur Office advises clients on general contract formation and administration 
                      issues and our advisory services cover:
                      <ul>
                        <li>General Contract</li>
                        <li>Sales Contract</li>
                        <li>Construction contract and related issues </li>
                      </ul>
                      </p>
                </div>
                </div>
                <div className='service-content2'>
                <div>
                <img src='images/ajency.jpeg'/>
                  </div>
                  <br/>
                <div>
                <h2>Agency Law</h2>
                <p>In today’s complex and diversified business environment, it will be difficult to handle business by oneself and 
                    it may require appointing an agent on once behalf. Our office advices clients how to handle their relationship with
                     either the agent or the principal considering different areas of law. </p>
                </div>
                </div>
                
                <div className='service-content2'>
                <div> 
                <img src='images/banking.jpeg'/>
                </div>
                <br/>
                <div>
                <h2>Banking and Finance</h2>
                <p>Over the years, our office has built key industry practice in the banking and financial sector by advising local
                     and global banking entities, development funds and micro finance institutions. Our office has acted as lender’s 
                    counsel as well as representing clients figuring in the relevant transaction as borrowers. Among the services;</p>
                </div>
                </div>
                <div className='service-content2'>
                 <div>
                 <img src='images/corporate.jpeg'/>
                 </div>
                 <br/>
                 <div>
                <h2> Corporate Law</h2>
                <p>Our office and its lawyers have an experience in proactively pinpointing and maximizing on regulatory 
                    opportunities while ensuring a compliant presence and operation. We offer best-in-class and actionable
                     advisory and representation services on corporate structuring, tax compliance, licensing, privatization,
                      corporate finance and company secretariat services, among others. Our extensive experience in corporate
                       legal service, complemented by our close working relationship with regulatory and policy makers, helps
                        to overcome legal and regulatory challenges faced by our clients.</p>
                </div>
                </div>
                <div className='service-content2'>
                <div>
                <img src='images/labor.jpeg'/>
                </div>
                <br/>
                <div>
                <h2>Employment and Labour Law</h2>
                <p>Our office advises clients in all matters involving labor and employment law, including on hiring,
                     terminations, drafting contracts and workplace policies and procedures, and workplace investigations.
                      Our potential clients largely consist of foreign companies doing business in Ethiopia. </p>
                </div>
                </div>
                <div className='service-content2'>
                <div>
                <img src='images/arbitration.jpeg'/>
                </div>
                <br/>
                <div>
                <h2>Arbitration Law</h2>
                <p>Complex business relationships at times can lead to disagreements.
                     Often is the case that businesses frequently resolve their 
                     disputes amicably and that fosters an improved and continued relationship between
                      the parties. When that fails however, litigation before courts or other alternative
                       dispute settlement procedures are required. In this context, our office is
                        ready to serve clients in different contexts of dispute resolution mechanisms</p>
                </div>
                </div>
                {/* <div className='service-content2'>
                <div>
                <img src='images/family.png'/>
                </div>
                <div>
                <h2>Family Law</h2>
                <p>The mission of our company is usually to provide high-quality,
                         innovative and cost-effective IT solutions that meet the needs and
                          expectations of our clients and help them achieve their goals</p>
                </div>
                </div> */}
            </div>
        </section>
        <ScrollToToButton/>
        <br/>
        <br/>
        <Footer/>
    </React.Fragment>
  )
}

export default Service