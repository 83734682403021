import React, { useState, useEffect } from 'react';
import { FaArrowUp } from 'react-icons/fa';

const ScrollToToButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Show/hide the button based on scroll position
    useEffect(() => {
      const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
  
      window.addEventListener('scroll', toggleVisibility);
  
      return () => {
        window.removeEventListener('scroll', toggleVisibility);
      };
    }, []);
  
    // Scroll to top when button is clicked
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };
  
  return (
    <div>
    {isVisible && (
      <button className="scroll-to-top-button" onClick={scrollToTop}>
        <FaArrowUp />
      </button>
    )}
  </div>
  )
}

export default ScrollToToButton