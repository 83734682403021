import React, { useState } from 'react'
import "./Navbar.css";
import {  FaBalanceScale } from 'react-icons/fa';
import { Link, NavLink } from "react-router-dom";

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);
  return (
    <nav>
    <Link to="/" className="title">
    <p>
    {/* <FaBalanceScale style={{ color: '#ec9f6b', fontSize: '60px' }}/> */}
    Law Office of <span> Temesgen Sisay</span>
    </p>
    </Link>
    <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <ul className={menuOpen ? "open" : ""}>
    <li>
        <NavLink to="/">Home</NavLink>
      </li>
      <li>
        <NavLink to="/about">About</NavLink>
      </li>
      <li>
        <NavLink to="/service">Services</NavLink>
      </li>
      <li>
        <NavLink to="/contacts">Contact</NavLink>
      </li>
      {/* <li>
        <NavLink to="/login">Login</NavLink>
      </li> */}
    </ul>
  </nav>
  );
};

export default Navbar
