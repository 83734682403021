import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Contact = () => {
  const apiurl = "https://teme.temesgensisay.com";
  const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [values, setValues] = useState({
        name: '',
        email: '',
        date: '',
        phone: '',
        subject: '',
        message: ''
        // password: ''
    })
    const handleSubmit = (e) =>{
        e.preventDefault();
     
        axios.post(`${apiurl}/api/createcontact`, values)
        .then(res => {
            if(res.data.status){
              //  navigate('/')
              setMessage(res.data.msg);
            }
            else{
                alert(res.data.Error)
            }
            // console.log(res);
            // navigate('/dashboard/news')
        })
        .catch(err => console.log(err))
        setMessage('Error updating data');
    }
    
  
    return (
      <div className='contact'> 
      
        <div className=' contact-content'>
        <h4 className='pt-3'>put your comment</h4>
      <form onSubmit={handleSubmit}>
        <div className='form'>
        <div className='form-flex'>
      <div className="d-flex mb-3 p-3 w-100">
      <label htmlFor='' className='p-2'> Name:</label>
        <input
          type="text"
          name="name"
          placeholder="ensert name"
          onChange={e => setValues({...values, name: e.target.value})}
          className='form-control rounded-0 w-100'
        />
        </div>
        <div className="d-flex mb-3 p-3 w-100">
        <label htmlFor='' className='p-2'> Email:</label>
        <input
          type="email"
          name="email"
          placeholder="ensert email"
          onChange={e => setValues({...values, email: e.target.value})}
          className='form-control rounded-0 w-100'
        />
        </div>
        <div className="d-flex mb-3 p-3 w-100">
        <label htmlFor='' className='p-2'> Date:</label>
          <input
          type="date"
          name="date"
          placeholder="ensert date"
          onChange={e => setValues({...values, date: e.target.value})}
          className='form-control rounded-0 w-100'
        /></div>
        </div>
        <div className='form-flex'>
        {/* <div className="d-flex mb-3 p-3 w-100">
        <label htmlFor=''className='p-2'> Phone:</label>
        <input
          type="text"
          name="phone"
          placeholder="ensert phone number"
          onChange={e => setValues({...values, phone: e.target.value})}
          className='form-control rounded-0 w-100'
        />
        </div> */}
        <div className="d-flex mb-3 p-3 w-100">
        <label htmlFor=''className='p-2'> Subject:</label>
        <input
          type="text"
          name="subject"
          placeholder="ensert subject"
          onChange={e => setValues({...values, subject: e.target.value})}
          className='form-control rounded-0 w-100'
        />
        </div>
        <div className="d-flex mb-3 p-3 w-100">
        <label htmlFor=''className='p-2' > Message:</label>
         <textarea
          type="text"
          name="message"
          placeholder="ensert message"
          onChange={e => setValues({...values, message: e.target.value})}
          className='form-control rounded-0 w-100'
        />
        </div>
        </div>
        </div>
       
        <button type="submit" className='btn btn-success w-25'>Insert Data</button>
        <br/>
        <br/>
        {message && <p>{message}</p>}
        <br/>
      </form>
      </div>
      </div>
    );



//     const [ values, setValues] = useState({
//         name: '',
//         email: '',
//         date: '',
//         subject: '',
//         message: ''
//     })
//     const navigate = useNavigate();
    
//     const handleInput = (event) =>{
//         setValues(prev => ({...prev, [event.target.name]: [event.target.value]}))
//     }
//     function handleSubmit (event)  {
//         event.preventDefault();
//        axios.post('http://localhost:5000/contact', values)
//             .then(res => {
                
//                     navigate('/admin')
                
               
//             })
//             .catch(err => console.log(err));
//         }
//   return (
//     <div className='d-flex justify-content-center align-items-center bg-secondary  contact'>
//         <div className='bg-white text-aligns-center rounded w-50'>
//            {/* <h2>Sign up</h2> */}
//             <form action='' onSubmit={handleSubmit}>
//             <div className="mb-3 w-100">
//                     {/* <lable htmlFor="name"><strong>name </strong></lable> */}
//                     <input type='text' placeholder='Enter user name' name='name'
//                     onChange={handleInput}
//                     className='form-control rounded-0 w-100'/>
                    
//                 </div>
//                 <div className="mb-3">
//                     {/* <lable htmlFor="email"><strong>email </strong></lable> */}
//                     <input type='email' placeholder='Enter email' name='email'
//                      onChange={handleInput}
//                      className='form-control rounded-0'/>
                    
//                 </div>
//                 <div className="mb-3">
//                     {/* <lable htmlFor="date"> <strong>date </strong></lable> */}
//                     <input type='date' placeholder='Enter Date' name='date'
//                     onChange={handleInput}
//                     className='form-control rounded-0'/>
                   
//                 </div>
//                 <div className="mb-3">
//                     {/* <lable htmlFor="subject"> <strong>subject </strong></lable> */}
//                     <input type='text' placeholder='Enter subject' name='subject'
//                     onChange={handleInput}
//                     className='form-control rounded-0'/>
                   
//                 </div>
//                 <div className="mb-3">
//                     {/* <lable htmlFor="message"> <strong>message </strong></lable> */}
//                     <input type='area' placeholder='Enter Message' name='message '
//                     onChange={handleInput}
//                      className='form-control rounded-0 '/>
                   
//                 </div>
//                 <button type='submit' className='btn btn-success w-100 '><strong>Send Message</strong></button>
//                 {/* <p>You have already exist</p>
//                 <Link to='/login' className='btn btn-default border w-100 bg-light'>Log in</Link> */}
//             </form>
//         </div>
//     </div>
//   )
  
}

export default Contact