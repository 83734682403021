// src/components/ChangePassword.js
import React, { useState } from 'react';
import axios from 'axios';

const ChangePassword = () => {
  const apiurl = "https://teme.temesgensisay.com";
  const [username, setUsername] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [erormessage, setErorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiurl}/change-password`, {
        username,
        oldPassword,
        newPassword,
      });
      console.log(response.data);
      setMessage(response.data.message);
    } catch (err) {
      console.error(err);
      setErorMessage("incorrect password or username");
    }
  };

  return (
    <div className='d-flex justify-content-center align-items-center vh-100'>
    <div className='bg-white p-3 rounded w-50'>
    <form onSubmit={handleSubmit}>
      <h2>Change Password</h2>
      <div className="mb-3">
      <label htmlFor='name'><strong>User Name</strong></label>
      <input
        type="text"
        placeholder="Username"
        value={username} className='form-control rounded-0'
        onChange={(e) => setUsername(e.target.value)}
      />
      </div>
      <div className="mb-3 ">
      <label htmlFor='name'><strong>Old Password</strong></label>
      <input
        type="password"
        placeholder="Old Password"
        value={oldPassword} className='form-control rounded-0'
        onChange={(e) => setOldPassword(e.target.value)}
      />
      </div>
      <div className="mb-3">
      <label htmlFor='name'><strong>New Passwor</strong></label>
      <input
        type="password"
        placeholder="New Password"
        value={newPassword} className='form-control rounded-0'
        onChange={(e) => setNewPassword(e.target.value)}
      /></div>
      <button type="submit" className='btn btn-success w-100 rounded-0'>Change Password</button>
      <h4 style={{color: "red"}}> {erormessage ? erormessage : ''}</h4>
      <h4> {message ? message : ''}</h4>
    </form>
    </div></div>
  );
};

export default ChangePassword;
