import React, { useState } from 'react'
import Maps from './Maps'
import Contact from './Contact'
import Footer from './Footer'
import Navbar from '../Navbar'


const Contacts = () => {

  return (
    <React.Fragment>
        <div className=''>
        <Navbar/> 
        <div style={{textAlign:'center', padding: "40px", background: "#d9dee8"}}>
        <h3 >Contac Us</h3> 
        </div>
        {/* <Contact/>  */}
        <Maps/>
        <Footer/>
        </div>

     
    </React.Fragment>
  )
}

export default Contacts